import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Banded Good Mornings 4×10 & DB Death March 4x50ft`}</p>
    <p>{`Deadlifts 4×4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`3-Bar Facing Burpees`}</p>
    <p>{`2-Deadlifts (225/155)`}</p>
    <p>{`1-Ring Muscle Up`}</p>
    <p>{`4-Bar Facing Burpees`}</p>
    <p>{`3-Deadlifts`}</p>
    <p>{`2-Ring Muscle Ups`}</p>
    <p>{`5-Bar Facing Burpees`}</p>
    <p>{`4-Deadlifts`}</p>
    <p>{`3-Ring Muscle Ups`}</p>
    <p>{`etc.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Granite Games Open starts this Wednesday, April 10th!  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Sign up at: `}<a parentName="em" {...{
            "href": "https://thegranitegames.com"
          }}>{`https://thegranitegames.com`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`All members and their families are invited to watch the air show
and Thunder at The Ville this Saturday!  We’ll have a potluck, play some
games, and hang out.  We’ll start around 2:00pm but we’ll be open all
day until Thunder is over.  Hope you can make it!  We’ll have our normal
class times on Saturday as well.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      